import * as common from '@tencent/toggle-client-js-common';
import browserPlatform from './browser-platform';

export function initialize(env, configs = {}, isTest) {
  const platform = browserPlatform(configs);
  const clientVars = common.initialize(env, configs, platform);
  const client = clientVars.client;
  if (isTest) {
    return client;
  }

  if (document.readyState !== 'complete') {
    window.addEventListener('load', clientVars.start);
  } else {
    clientVars.start();
  }
  window.addEventListener('beforeunload', () => {
    platform.pageIsClosing = true;
    client.close();
  });
  return client;
}

export const TOGGLE_DEV = 'dev';
export const TOGGLE_DEV_SECURE = 'secure_dev';
export const TOGGLE_FORMAL = 'formal';
export const TOGGLE_FORMAL_V6 = 'formal_v6';
