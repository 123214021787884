import newHttpRequest from './http-request';

export default function makeBrowserPlatform(options) {
  const ret = {};

  ret.pageIsClosing = false; // This will be set to true by index.js if the page is closing.

  // XMLHttpRequest may not exist if we're running in a server-side rendering context
  if (window.XMLHttpRequest) {
    ret.httpRequest = (method, url, headers, body) => newHttpRequest(method, url, headers, body, ret.pageIsClosing);
  }

  let hasCors;
  ret.httpAllowsPost = () => {
    if (hasCors === undefined) {
      hasCors = window.XMLHttpRequest ? 'withCredentials' in new window.XMLHttpRequest() : false;
    }
    return hasCors;
  };

  // 应该没用
  const eventUrlTransformer = options && options.eventUrlTransformer;
  ret.getCurrentUrl = () => (eventUrlTransformer ? eventUrlTransformer(window.location.href) : window.location.href);

  ret.isDoNotTrack = () => {
    let flag;
    if (window.navigator && window.navigator.doNotTrack !== undefined) {
      flag = window.navigator.doNotTrack; // FF, Chrome
    } else if (window.navigator && window.navigator.msDoNotTrack !== undefined) {
      flag = window.navigator.msDoNotTrack; // IE 9/10
    } else {
      flag = window.doNotTrack; // IE 11+, Safari
    }
    return flag === 1 || flag === true || flag === '1' || flag === 'yes';
  };

  try {
    if (window.localStorage) {
      ret.localStorage = {
        get: key => new Promise(resolve => {
          resolve(window.localStorage.getItem(key));
        }),
        syncGet: key => window.localStorage.getItem(key),
        set: (key, value) => new Promise(resolve => {
          window.localStorage.setItem(key, value);
          resolve();
        }),
        clear: key => new Promise(resolve => {
          window.localStorage.removeItem(key);
          resolve();
        }),
        syncClear: key => window.localStorage.removeItem(key),
      };
    }
  } catch (e) {
    // In some browsers (such as Chrome), even looking at window.localStorage at all will cause
    // a security error if the feature is disabled.
    ret.localStorage = null;
  }

  ret.userAgent = 'JSClient';
  ret.getOsPlatform = () => window.navigator.platform;

  return ret;
}
