function isSyncXhrSupported() {
  // Temporary logic to disable synchronous XHR in Chrome v73 and above.
  // We assume other browser support this functionality.
  const userAgent = window.navigator && window.navigator.userAgent;
  if (userAgent) {
    // Chrome/10.
    const chromeMatch = userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    if (chromeMatch) {
      const version = parseInt(chromeMatch[2], 10);
      return version < 73;
    }
  }

  return true;
}

const emptyResult = { promise: Promise.resolve({ status: 200, headers: () => null, body: null }) };

export default function newHttpRequest(method, url, headers, body, pageIsClosing) {
  if (pageIsClosing) {
    // When page is closing, we have to use synchronous XHR (Until sendBeacon used).
    // Not all browser supports this functionality.
    if (!isSyncXhrSupported()) {
      return emptyResult;
      // Fake success response, because we don't want retry.
    }
  }

  const xhr = new window.XMLHttpRequest();
  xhr.open(method, url, !pageIsClosing);
  for (const key in headers || {}) {
    if (headers.hasOwnProperty(key)) {
      xhr.setRequestHeader(key, headers[key]);
    }
  }
  if (pageIsClosing) {
    xhr.send(body); // We specified synchronous mode when called xhr.open
    return emptyResult; // No more retry.
  }
  let cancelled;
  const p = new Promise((resolve, reject) => {
    xhr.addEventListener('load', () => {
      if (cancelled) {
        return;
      }
      resolve({
        status: xhr.status,
        header: key => xhr.getResponseHeader(key),
        body: xhr.responseText,
      });
    });
    xhr.addEventListener('error', () => {
      if (cancelled) {
        return;
      }
      reject(new Error());
    });
    xhr.send(body);
  });
  const cancel = () => {
    cancelled = true;
    xhr.abort();
  };
  return { promise: p, cancel };
}

